import { Typography } from '@mui/material';
import '../css/App.css';


function Home() {
    return (
        <div className='App'>
            <Typography variant="h1" fontFamily="Poppins" fontWeight={"bold"} className='appTittles'>
                Ingrese un token de terminal para continuar, consulte con el software de gestion
            </Typography>
        </div>
    );
}

export default Home;