import { Box, AppBar, Toolbar } from '@mui/material';
import DuxLogo from '../assets/dux-logo.jpg';

function Footer() {
   return (
   <a href="https://www.duxsoftware.com.ar/" target="_blank" rel="noopener noreferrer">
    
     <AppBar position="static" color="primary" sx={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: 'transparent', boxShadow: 'none', alignItems: 'center', justifyContent: 'space-evenly' }}>
       <Toolbar sx={{ '@media (min-width: 600px)': { paddingLeft: '30%', paddingRight: '20%', }, }}>
        <img src={DuxLogo} alt="Dux Logo" className='duxLogo' />
       </Toolbar>
     </AppBar>
   </a>
   );
}

export default Footer;